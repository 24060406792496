<template>
    <div class="mailing-modal">
        <h1>
            <d-content class="text-center" key_="mailing-title"></d-content>
        </h1>
<!--        <div class="mailing-modal_emails">-->
<!--            <span v-for="item in params.ids">{{ item.referral_account_id }}, </span>-->
<!--        </div>-->
        <b-textarea v-model="message" :placeholder="$content('mailing-text')"></b-textarea>
        <ProjectButton @click.native="confirm" variant="app">
            <div v-html="$content('mailing-submit')"></div>
        </ProjectButton>
    </div>
</template>

<script>
import ProjectButton from "../Buttons/ProjectButton";
export default {
    name: "Mailing",
    components: {ProjectButton},
    data() {
        return {
            message: ''
        }
    },
    computed: {
        params() {
            return this.$store.getters.activeModalParams
        }
    },
    methods: {
        confirm() {
            let ids = [];
            this.params.ids.forEach(x => ids.push(x.referral_id))
            this.$http('referral').mailing({ids: ids, message: this.message})
                .finally(() => {
                    this.$root.$emit('newsDeleted');
                    this.$modal.close()
                })
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .mailing-modal {
        background: white;
        border-radius: 15px;
        padding: 45px 30px 15px 30px;
        width: 550px;
        font-family: Montserrat-Medium, sans-serif;

        @include only-lg-down {
            width: 100%;
        }

        &_emails {
            background: #efefef;
            padding: 10px;
            border-radius: 5px;
            margin: 25px 0;
            height: 100px;
            overflow-y: auto;

            & span {
                color: var(--main-dark-paper);
            }
        }


        & textarea {
            background: #efefef;
            margin-bottom: 50px;
            border: none;
            height: 150px;
        }

        & h1 {
            text-align: center;
            color: var(--main-app);
            font-size: 24px;
            margin-bottom: 50px;
        }
    }
</style>